import { Collapse } from "@blueprintjs/core";
import { CellType } from "@hex/common";
import React, { useCallback, useState } from "react";

import { useScrollToCell } from "../../../hooks/cell/useScrollToCell.js";

import { MatchHeader } from "./MatchHeader";
import { IndexedSearchItem } from "./utils.js";

export interface ProjectSearchResultProps {
  type: CellType | "ProjectMatch";
  groupId: string;
  firstMatch: IndexedSearchItem;
  matchesForCell: IndexedSearchItem[];
  renderItem: (item: IndexedSearchItem, index: number) => void;
  countsByGroup: Record<string, number>;
  setActiveItem: (match: IndexedSearchItem) => void;
  replaceCellsCallback: (matches: IndexedSearchItem[]) => void;
  showReplaceAction: boolean;
}

export const ProjectSearchResult: React.ComponentType<ProjectSearchResultProps> =
  React.memo(function ProjectSearchResult({
    countsByGroup,
    firstMatch,
    groupId,
    matchesForCell,
    renderItem,
    replaceCellsCallback,
    setActiveItem,
    showReplaceAction,
    type,
  }: ProjectSearchResultProps) {
    const scrollToCell = useScrollToCell();
    const [collapseResultCell, setResultsOpen] = useState(true);

    // If the header of a search result list is selected, scroll to that cell and select the first match.
    const goToCellCallback = useCallback(() => {
      if (firstMatch.cellId != null) {
        scrollToCell(firstMatch.cellId, {
          scrollTarget: "source",
          scrollBehavior: "smooth",
        });
      }
      setActiveItem(firstMatch);
    }, [firstMatch, setActiveItem, scrollToCell]);

    const replaceAllForCell = useCallback(() => {
      replaceCellsCallback(matchesForCell);
    }, [replaceCellsCallback, matchesForCell]);

    return (
      <span>
        <MatchHeader
          collapseSection={setResultsOpen}
          expanded={collapseResultCell}
          goToCell={goToCellCallback}
          label={firstMatch.cellLabel}
          matchCount={countsByGroup[groupId]}
          replaceAllForCell={replaceAllForCell}
          showReplaceAction={showReplaceAction}
          type={type}
        />
        <Collapse key={groupId} isOpen={collapseResultCell}>
          {matchesForCell.map((match, i) => renderItem(match, i))}
        </Collapse>
      </span>
    );
  });
