import { Icon } from "@blueprintjs/core";
import { ProjectLanguage, typedObjectKeys } from "@hex/common";
import React from "react";
import styled from "styled-components";

import { HexButton } from "../../../hex-components/HexButton.js";
import { HexMenu, HexMenuItem } from "../../../hex-components/HexMenuItem.js";
import { HexPopover } from "../../../hex-components/HexPopover.js";
import {
  SessionStorageKeys,
  useSessionStorage,
} from "../../../hooks/useSessionStorage.js";
import { useProjectContext } from "../../../util/projectContext.js";
import { SingleChevronDownIcon, TickIcon } from "../../icons/CustomIcons.js";
import { CellFilterShowType } from "../constants.js";

const StyledHexMenuItemText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

/**
 * Component that allows us to filter project cells in a sidebar tab (like the project search or outline tabs)
 */
export const FilterProjectCells: React.ComponentType = React.memo(
  function FilterProjectCells() {
    const { hexId } = useProjectContext();
    const [outlineShowType, setOutlineShowType] = useSessionStorage(
      SessionStorageKeys.CELL_FILTER_SHOW_TYPE(hexId),
    );
    const { language } = useProjectContext();

    return (
      <HexPopover
        content={
          <HexMenu>
            {typedObjectKeys(CellFilterShowType)
              .filter((key) => {
                if (
                  key === "Input" ||
                  key === "Visualizations" ||
                  key === "Transformations"
                ) {
                  return language === ProjectLanguage.PYTHON;
                }
                return true;
              })
              .map((key) => {
                const cellType = CellFilterShowType[key];
                const isActive = outlineShowType.label === cellType.label;
                return (
                  <HexMenuItem
                    key={key}
                    icon={cellType.icon}
                    selected={true}
                    text={
                      <StyledHexMenuItemText>
                        {cellType.display}
                        {isActive && <Icon icon={<TickIcon />} />}
                      </StyledHexMenuItemText>
                    }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => setOutlineShowType(cellType)}
                  />
                );
              })}
          </HexMenu>
        }
        minimal={true}
        placement="bottom-end"
      >
        <HexButton
          rightIcon={<SingleChevronDownIcon iconSize={12} />}
          small={true}
          subtle={true}
          text={outlineShowType.display}
        />
      </HexPopover>
    );
  },
);
